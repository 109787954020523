import React, { useState } from 'react';
import {
  BarChart, Bar, AreaChart, Area, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import styles from "./KoladaDashboard.module.css";

const ChartComponent = ({ 
  kpi, 
  data, 
  selectedMunicipalities, 
  selectedRegions, 
  municipalityList, 
  regionList, 
  defaultChartType 
}) => {
  const [chartType, setChartType] = useState(defaultChartType);

  const colors = ['#005a69', '#7d2b40', '#e06c00', '#0071a1', '#3a6e31', '#7a5589', '#7d5740'];

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className={styles.customTooltip}>
          <p className={styles.tooltipLabel}>{`Year: ${label}`}</p>
          {payload.map((entry, index) => (
            <p key={index} style={{ color: entry.color }}>
              {`${entry.name}: ${entry.value}`}
            </p>
          ))}
        </div>
      );
    }
    return null;
  };

  const transformData = (data) => {
    const transformed = [];
    data.forEach(item => {
      let yearEntry = transformed.find(entry => entry.year === item.year);
      if (!yearEntry) {
        yearEntry = { year: item.year };
        transformed.push(yearEntry);
      }
      yearEntry[item.municipality] = item.value;
    });
    return transformed;
  };

  const getLocationName = (id) => {
    const municipality = municipalityList.find(m => m.id === id);
    if (municipality) return municipality.title;
    const region = regionList.find(r => r.id === id);
    if (region) return region.title;
    return id;
  };

  const renderChart = () => {
    if (!data || data.length === 0) {
      return <div className={styles.noData}>No data available</div>;
    }

    const ChartComponentType = chartType === 'bar' ? BarChart : chartType === 'area' ? AreaChart : LineChart;
    const DataComponentType = chartType === 'bar' ? Bar : chartType === 'area' ? Area : Line;

    const transformedData = transformData(data);
    const allLocations = [...selectedMunicipalities, ...selectedRegions];

    return (
      <ResponsiveContainer width="100%" height={400}>
        <ChartComponentType data={transformedData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="year" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          {allLocations.map((locationId, index) => (
            <DataComponentType
              key={locationId}
              type="monotone"
              dataKey={locationId}
              name={getLocationName(locationId)}
              stroke={colors[index % colors.length]}
              fill={colors[index % colors.length]}
            />
          ))}
        </ChartComponentType>
      </ResponsiveContainer>
    );
  };

  return (
    <div className={styles.chartContainer}>
      <h3 className={styles.chartTitle}>{kpi}</h3>
      <div className={styles.chartTypeSelector}>
        <button onClick={() => setChartType('line')} className={chartType === 'line' ? styles.active : ''}>Line</button>
        <button onClick={() => setChartType('bar')} className={chartType === 'bar' ? styles.active : ''}>Bar</button>
        <button onClick={() => setChartType('area')} className={chartType === 'area' ? styles.active : ''}>Area</button>
      </div>
      {renderChart()}
    </div>
  );
};

export default ChartComponent;