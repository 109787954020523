import React from 'react';
import styles from "./KoladaDashboard.module.css";

const SelectionSummary = ({ 
  selectedKpis, 
  selectedMunicipalities, 
  selectedRegions,
  selectedYears, 
  removeKpi, 
  removeMunicipality, 
  removeRegion,
  removeYear 
}) => {
  return (
    <div className={styles.selectionSummary}>
      <h3>Selected Items</h3>
      <div>
        <h4>KPIs</h4>
        {selectedKpis.map((kpi, index) => (
          <div key={index} className={styles.selectionItem}>
            <div>
              <strong>{kpi.title}</strong>
              <p>{kpi.description}</p>
            </div>
            <button onClick={() => removeKpi(kpi.id)}>X</button>
          </div>
        ))}
      </div>
      <div>
        <h4>Municipalities</h4>
        {selectedMunicipalities.map((municipality, index) => (
          <div key={index} className={styles.selectionItem}>
            {municipality.title} <button onClick={() => removeMunicipality(municipality.id)}>X</button>
          </div>
        ))}
      </div>
      <div>
        <h4>Regions</h4>
        {selectedRegions.map((region, index) => (
          <div key={index} className={styles.selectionItem}>
            {region.title} <button onClick={() => removeRegion(region.id)}>X</button>
          </div>
        ))}
      </div>
      <div>
        <h4>Years</h4>
        {selectedYears.map((year, index) => (
          <div key={index} className={styles.selectionItem}>
            {year} <button onClick={() => removeYear(year)}>X</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SelectionSummary;