import React, { useState } from 'react';
import styles from "./KoladaDashboard.module.css";

const CategorizedKpiSelection = ({ kpiList, selectedKpis, setSelectedKpis }) => {
  const [selectedCategory, setSelectedCategory] = useState(Object.keys(kpiList)[0] || '');

  const handleCheckboxChange = (kpiId) => {
    if (selectedKpis.includes(kpiId)) {
      setSelectedKpis(selectedKpis.filter(id => id !== kpiId));
    } else {
      setSelectedKpis([...selectedKpis, kpiId]);
    }
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  return (
    <div className={styles.categorizedSelection}>
      <label htmlFor="categorySelect" className={styles.categoryLabel}>Select Category:</label>
      <select
        id="categorySelect"
        value={selectedCategory}
        onChange={handleCategoryChange}
        className={styles.categorySelect}
      >
        {Object.keys(kpiList).map(category => (
          <option key={category} value={category}>{category}</option>
        ))}
      </select>
      <div className={styles.selectionList}>
        {kpiList[selectedCategory] && kpiList[selectedCategory].map(kpi => (
          <label key={kpi.id} className={styles.selectionItem}>
            <span 
              className={styles.kpiTitle}
              title={kpi.description} // Add this line
            >
              {kpi.title}
            </span>
            <input
              type="checkbox"
              value={kpi.id}
              checked={selectedKpis.includes(kpi.id)}
              onChange={() => handleCheckboxChange(kpi.id)}
              className={styles.checkbox}
            />
          </label>
        ))}
      </div>
    </div>
  );
};

export default CategorizedKpiSelection;