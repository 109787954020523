import React from 'react';
import styles from './KoladaDashboard.module.css';

const AnalysisButtons = ({ analyzeData, explainData, isAnalyzing, resetSelections }) => {
  return (
    <div className={styles.analysisButtonsContainer}>
      <button
        className={styles.analysisButton}
        onClick={() => analyzeData('trend')}
        disabled={isAnalyzing}
      >
        {isAnalyzing === 'trend' ? 'Analyzing...' : 'Trend Analysis'}
      </button>
      <button
        className={styles.analysisButton}
        onClick={() => analyzeData('comparison')}
        disabled={isAnalyzing}
      >
        {isAnalyzing === 'comparison' ? 'Analyzing...' : 'Comparison Analysis'}
      </button>
      <button
        className={styles.analysisButton}
        onClick={() => analyzeData('anomaly')}
        disabled={isAnalyzing}
      >
        {isAnalyzing === 'anomaly' ? 'Analyzing...' : 'Anomaly Detection'}
      </button>
      <button
        className={styles.analysisButton}
        onClick={explainData}
        disabled={isAnalyzing}
      >
        {isAnalyzing === 'explain' ? 'Explaining...' : 'Explain Data'}
      </button>
      <button
        className={`${styles.analysisButton} ${styles.resetButton}`}
        onClick={resetSelections}
      >
        Reset
      </button>
    </div>
  );
};

export default AnalysisButtons;