import React, { useState, useEffect, useCallback } from "react";
import ChartComponent from './ChartComponent';
import SelectionSummary from './SelectionSummary';
import CategorizedKpiSelection from './CategorizedKpiSelection';
import AnalysisButtons from './AnalysisButtons';
import ReactMarkdown from 'react-markdown';
import axios from "axios";
import styles from "./KoladaDashboard.module.css";
import './AnalysisButtons.css';
import InteractiveAnalysis from './InteractiveAnalysis';

const API_BASE_URL = "https://api.kolada.se/v2";

const KoladaDashboard = () => {
  const [kpiData, setKpiData] = useState({});
  const [kpiList, setKpiList] = useState({});
  const [municipalityList, setMunicipalityList] = useState([]);
  const [regionList, setRegionList] = useState([]);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [selectedKpis, setSelectedKpis] = useState([]);
  const [selectedMunicipalities, setSelectedMunicipalities] = useState([]);
  const [selectedYears, setSelectedYears] = useState([]);
  const [analysis, setAnalysis] = useState("");
  const [isAnalyzing, setIsAnalyzing] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isSelectionCollapsed, setIsSelectionCollapsed] = useState(false);
  const [savedDashboards, setSavedDashboards] = useState([]);

  const fetchKpiList = useCallback(async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/kpi`);
      const metadata = response.data.values;
      const categorizedKpis = categorizeKpis(metadata);
      setKpiList(categorizedKpis);
    } catch (error) {
      console.error("Error fetching KPI list:", error);
      setError("Error fetching KPI list");
    }
  }, []);

  useEffect(() => {
    fetchKpiList();
    fetchMunicipalityAndRegionList();
  }, [fetchKpiList]);

  const categorizeKpis = (kpis) => {
    const categories = kpis.reduce((acc, kpi) => {
      const category = kpi.operating_area || 'Other';
      if (!acc[category]) acc[category] = [];
      acc[category].push(kpi);
      return acc;
    }, {});
    return categories;
  };

  const fetchMunicipalityAndRegionList = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/municipality`);
      const allMunicipalities = response.data.values;
      
      const municipalities = allMunicipalities.filter(m => m.type === 'K');
      const regions = allMunicipalities.filter(m => m.type === 'L');
      
      setMunicipalityList(municipalities);
      setRegionList(regions);
    } catch (error) {
      console.error("Error fetching municipality and region list:", error);
      setError("Error fetching municipality and region list");
    }
  };

  const fetchData = async () => {
    if (selectedKpis.length === 0 || (selectedMunicipalities.length === 0 && selectedRegions.length === 0) || selectedYears.length === 0) {
      setError("Please select at least one KPI, one municipality or region, and one year");
      return;
    }
  
    setIsLoading(true);
    setError(null);
    const newKpiData = {};
  
    try {
      for (const kpi of selectedKpis) {
        const locationIds = [...selectedMunicipalities, ...selectedRegions].join(",");
        const years = selectedYears.join(",");
        const url = `${API_BASE_URL}/data/kpi/${kpi}/municipality/${locationIds}/year/${years}`;
        const response = await axios.get(url);
  
        if (response.data.values && response.data.values.length > 0) {
          newKpiData[kpi] = response.data.values.map((item) => ({
            year: item.period,
            municipality: item.municipality,
            value: item.values[0]?.value || null,
          }));
        }
      }
      setKpiData(newKpiData);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Error fetching data. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  const toggleSelectionCollapse = () => {
    setIsSelectionCollapsed(!isSelectionCollapsed);
  };

  const saveDashboard = () => {
    const newDashboard = {
      id: Date.now(),
      name: `Dashboard ${savedDashboards.length + 1}`,
      selectedKpis,
      selectedMunicipalities,
      selectedRegions,
      selectedYears
    };
    setSavedDashboards([...savedDashboards, newDashboard]);
  };

  const loadDashboard = (dashboard) => {
    setSelectedKpis(dashboard.selectedKpis);
    setSelectedMunicipalities(dashboard.selectedMunicipalities);
    setSelectedRegions(dashboard.selectedRegions);
    setSelectedYears(dashboard.selectedYears);
    fetchData();
  };

  const removeKpi = (kpi) => {
    setSelectedKpis(selectedKpis.filter(id => id !== kpi));
  };

  const removeMunicipality = (municipality) => {
    setSelectedMunicipalities(selectedMunicipalities.filter(id => id !== municipality));
  };

  const removeRegion = (region) => {
    setSelectedRegions(selectedRegions.filter(id => id !== region));
  };

  const removeYear = (year) => {
    setSelectedYears(selectedYears.filter(y => y !== year));
  };

  const getAnalysisPrompt = (type, kpiTitles, kpiData) => {
    const dataString = Object.entries(kpiData)
      .map(([kpi, data]) => {
        return `${kpi}: ${data.map(item => `${item.municipality} (${item.year}): ${item.value}`).join(', ')}`;
      })
      .join('\n');
  
    const basePrompt = `
      Analyze the following data for ${kpiTitles.join(', ')} in ${[...selectedMunicipalities, ...selectedRegions].map(id => municipalityList.find(m => m.id === id)?.title || regionList.find(r => r.id === id)?.title || id).join(', ')} from ${selectedYears.join(', ')}:
  
      ${dataString}
  
      Provide a brief summary (about two paragraphs) addressing the following points without being too technical, using rounded numbers and avoiding KPI and municipality/region id:s:
    `;
  
    switch(type) {
      case 'trend':
        return basePrompt + `
          1. The main trend direction for each KPI
          2. Any significant changes or turning points
          3. A simple conclusion about the overall trend
        `;
      case 'comparison':
        return basePrompt + `
          1. Identify the best and worst performing municipalities for each KPI
          2. Note any significant gaps between municipalities
          3. Provide a brief insight into what these differences might mean
        `;
      case 'anomaly':
        return basePrompt + `
          1. Identify any clear outliers or unusual data points
          2. Briefly explain why these might be considered anomalies
          3. Suggest one possible reason for each anomaly
        `;
      case 'explain':
        return basePrompt + `
          1. Explain what each KPI measures
          2. Provide context on why these KPIs are important
          3. Highlight any interesting patterns or relationships in the data
        `;
      default:
        return '';
    }
  };
  
  const analyzeData = async (type) => {
    if (Object.keys(kpiData).length === 0) {
      setError("Please fetch data first");
      return;
    }
  
    setIsAnalyzing(type);
    setError(null);
  
    const kpiTitles = selectedKpis.map(
      (kpi) => Object.values(kpiList).flat().find((item) => item.id === kpi)?.title || kpi
    );
  
    const analysisPrompt = getAnalysisPrompt(type, kpiTitles, kpiData);
  
    try {
      const response = await axios.post("/analyze", { prompt: analysisPrompt });
  
      if (response.data && response.data.content && response.data.content[0]) {
        setAnalysis(response.data.content[0].text);
      } else {
        throw new Error("Unexpected response format from server");
      }
    } catch (error) {
      console.error("Error analyzing data:", error);
      setError(`An error occurred during data analysis: ${error.message}`);
    } finally {
      setIsAnalyzing(null);
    }
  };
  
  const explainData = async () => {
    await analyzeData('explain');
  };

  const resetSelections = () => {
    setSelectedKpis([]);
    setSelectedMunicipalities([]);
    setSelectedRegions([]);
    setSelectedYears([]);
    setKpiData({});
    setAnalysis("");
  };

  return (
    <div className={styles.dashboard}>
      <h1 className={styles.title}>Kolada Data Dashboard</h1>
  
      <button onClick={toggleSelectionCollapse}>
        {isSelectionCollapsed ? 'Expand Selection' : 'Collapse Selection'}
      </button>
  
      {!isSelectionCollapsed && (
        <>
          <div className={styles.selectionGrid}>
            <div>
              <h3>Select KPIs</h3>
              <CategorizedKpiSelection 
                kpiList={kpiList}
                selectedKpis={selectedKpis}
                setSelectedKpis={setSelectedKpis}
              />
            </div>
          </div>
  
          <div className={styles.selectionGrid}>
            <div>
              <h3>Select Municipalities</h3>
              <div className={styles.selectionList}>
                {municipalityList.map((municipality) => (
                  <label key={municipality.id} className={styles.selectionItem}>
                    <span>{municipality.title}</span>
                    <input
                      type="checkbox"
                      value={municipality.id}
                      checked={selectedMunicipalities.includes(municipality.id)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedMunicipalities([...selectedMunicipalities, municipality.id]);
                        } else {
                          setSelectedMunicipalities(selectedMunicipalities.filter((id) => id !== municipality.id));
                        }
                      }}
                    />
                  </label>
                ))}
              </div>
            </div>
  
            <div>
              <h3>Select Regions</h3>
              <div className={styles.selectionList}>
                {regionList.map((region) => (
                  <label key={region.id} className={styles.selectionItem}>
                    <span>{region.title}</span>
                    <input
                      type="checkbox"
                      value={region.id}
                      checked={selectedRegions.includes(region.id)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedRegions([...selectedRegions, region.id]);
                        } else {
                          setSelectedRegions(selectedRegions.filter((id) => id !== region.id));
                        }
                      }}
                    />
                  </label>
                ))}
              </div>
            </div>
  
            <div>
              <h3>Select Years</h3>
              <div className={styles.selectionList}>
                {Array.from({ length: 30 }, (_, i) => 2024 - i).map((year) => (
                  <label key={year} className={styles.selectionItem}>
                    <span>{year}</span>
                    <input
                      type="checkbox"
                      value={year}
                      checked={selectedYears.includes(year.toString())}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedYears([...selectedYears, year.toString()]);
                        } else {
                          setSelectedYears(selectedYears.filter((y) => y !== year.toString()));
                        }
                      }}
                    />
                  </label>
                ))}
              </div>
            </div>
          </div>
  
          <SelectionSummary
            selectedKpis={selectedKpis.map(id => Object.values(kpiList).flat().find(kpi => kpi.id === id))}
            selectedMunicipalities={selectedMunicipalities.map(id => municipalityList.find(m => m.id === id))}
            selectedRegions={selectedRegions.map(id => regionList.find(r => r.id === id))}
            selectedYears={selectedYears}
            removeKpi={removeKpi}
            removeMunicipality={removeMunicipality}
            removeRegion={removeRegion}
            removeYear={removeYear}
          />
        </>
      )}
  
      <button className={styles.fetchDataButton} onClick={fetchData}>
        Fetch Data
      </button>
  
      <button onClick={saveDashboard}>Save Dashboard</button>
  
      <select onChange={(e) => loadDashboard(savedDashboards[e.target.value])}>
        <option value="">Load Saved Dashboard</option>
        {savedDashboards.map((dashboard, index) => (
          <option key={dashboard.id} value={index}>{dashboard.name}</option>
        ))}
      </select>
  
      {error && <div className={styles.error}>{error}</div>}
  
      {isLoading ? (
        <div className={styles.loading}>Loading...</div>
      ) : (
        <>
          {Object.keys(kpiData).length > 0 && (
            <>
              <div className={styles.chartsContainer}>
                {Object.entries(kpiData).map(([kpi, data]) => (
                  <div key={kpi} className={styles.chartWrapper}>
                    <ChartComponent
                      kpi={Object.values(kpiList).flat().find((item) => item.id === kpi)?.title || kpi}
                      data={data}
                      selectedMunicipalities={selectedMunicipalities}
                      selectedRegions={selectedRegions}
                      municipalityList={municipalityList}
                      regionList={regionList}
                      defaultChartType="bar"
                    />
                  </div>
                ))}
              </div>
  
              {analysis && (
                <div className={styles.analysisContainer}>
                  <h2>AI Analysis</h2>
                  <ReactMarkdown>{analysis}</ReactMarkdown>
                </div>
              )}
              
              <div className={styles.interactiveSection}>
                <div className={styles.analysisButtonsWrapper}>
                  <h3>Analysis Options</h3>
                  <AnalysisButtons
                    analyzeData={analyzeData}
                    explainData={explainData}
                    isAnalyzing={isAnalyzing}
                    resetSelections={resetSelections}
                  />
                </div>
                <div className={styles.chatWindow}>
                  <h3>Interactive Analysis</h3>
                  <InteractiveAnalysis 
                    kpiData={kpiData}
                    selectedKpis={selectedKpis}
                    selectedMunicipalities={selectedMunicipalities}
                    selectedRegions={selectedRegions}
                    selectedYears={selectedYears}
                    kpiList={kpiList}
                    municipalityList={municipalityList}
                    regionList={regionList}
                  />
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default KoladaDashboard;
