import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import styles from './InteractiveAnalysis.module.css';

const InteractiveAnalysis = ({ kpiData, selectedKpis, selectedMunicipalities, selectedYears, kpiList, municipalityList }) => {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages]);

  const getKpiNames = () => {
    return selectedKpis.map(kpiId => {
      const kpi = Object.values(kpiList).flat().find(k => k.id === kpiId);
      return kpi ? kpi.title : kpiId;
    }).join(', ');
  };

  const getMunicipalityNames = () => {
    return selectedMunicipalities.map(munId => {
      const municipality = municipalityList.find(m => m.id === munId);
      return municipality ? municipality.title : munId;
    }).join(', ');
  };

  const sendMessage = async () => {
    if (!inputMessage.trim()) return;

    const newMessage = { type: 'user', content: inputMessage };
    setMessages(prevMessages => [...prevMessages, newMessage]);
    setInputMessage('');
    setIsLoading(true);

    try {
      const conversationHistory = messages.map(msg => `${msg.type === 'user' ? 'Human' : 'AI'}: ${msg.content}`).join('\n');
      
      const contextPrompt = `
        You are a helpful AI assistant designed to assist users with analyzing and interpreting Kolada data available on their dashboard. Your primary tasks include retrieving specific data points, performing statistical, trend, and comparative analyses, generating visualizations, and providing contextual insights and recommendations based on the visible data. Always interact with users politely and professionally, use clear and concise language, and ensure the accuracy of the information provided. Present information without being too technical, using rounded numbers and avoiding KPIs and municipality IDs. Encourage follow-up questions and offer proactive suggestions. If additional data is needed for more comprehensive analysis, inform the user to add more data to their dashboard. Your goal is to make the available Kolada data easy to understand and actionable for informed decision-making.        
        KPIs: ${getKpiNames()}
        Municipalities: ${getMunicipalityNames()}
        Years: ${selectedYears.join(', ')}

        Data summary: ${JSON.stringify(kpiData)}

        Conversation history:
        ${conversationHistory}

        Human: ${inputMessage}

        AI: Give a concise, helpful response focusing on the most relevant information. Remember previous context.
      `;

      const response = await axios.post("/analyze", { prompt: contextPrompt });
      
      if (response.data && response.data.content && response.data.content[0]) {
        const aiResponse = { type: 'ai', content: response.data.content[0].text };
        setMessages(prevMessages => [...prevMessages, aiResponse]);
      } else {
        throw new Error("Unexpected response format from server");
      }
    } catch (error) {
      console.error("Error sending message:", error);
      const errorMessage = { type: 'error', content: "Sorry, I couldn't process your request. Please try again." };
      setMessages(prevMessages => [...prevMessages, errorMessage]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.chatContainer}>
      <div className={styles.messagesContainer}>
        {messages.map((message, index) => (
          <div key={index} className={`${styles.message} ${styles[message.type]}`}>
            <ReactMarkdown>{message.content}</ReactMarkdown>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
      <div className={styles.inputContainer}>
        <input
          type="text"
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          placeholder="Ask about the Kolada data..."
          disabled={isLoading}
          className={styles.input}
        />
        <button onClick={sendMessage} disabled={isLoading} className={styles.sendButton}>
          {isLoading ? 'Sending...' : 'Send'}
        </button>
      </div>
    </div>
  );
};

export default InteractiveAnalysis;